
  import Vue from 'vue';
  import { mapActions } from 'vuex';
  import { DownloadApp } from '@/components/shared/download-app';

  export default Vue.extend({
    name: 'es-companies',

    components: {
      'es-download-app': DownloadApp,
    },

    data: () => ({
      email: null,
      name: null,
      message: null,
      phone: null,
      company: null,
    }),

    methods: {
      ...mapActions({
        officeRequest: 'officeRequest',
      }),

      onSubmit() {
        this.officeRequest(this.$data);
      },
    },
  });
